import {
  ElectionTime,
  PartyDominancePartyData,
  PartyDominanceTableRow,
  RawElectionTime
} from "@/src/common/types"
import {
  byKey,
  getPercentage,
  groupBy,
  raceHasElectionInTime,
  skipEmpty
} from "@/src/common/utils"
import { UPCOMING_ELECTIONS_YEAR } from "@/src/elections/business-layer/config"
import { TableHeaderV2 } from "@/src/elections/components/Table/TableV2"
import { FeatureCollection } from "geojson"

import {
  Campaign,
  Candidate,
  Constituency,
  ElectionPreference,
  PartyDominance
} from "../../data-layer/types"
import {
  DEFAULT_ELECTION_YEAR,
  DROPDOWN_NAME_OVERRIDE,
  ELECTION_PARTY_COLOR_OTHERS,
  HISTORICAL_RESULTS_START_YEAR,
  REGION
} from "../constants"
import { RACE_ADJECTIVE, RACE_TO_NAME } from "../constants"
import { URLFactory } from "../factories/URLFactory"
import {
  Article,
  Breadcrumb,
  CandidateCampaignInfo,
  CandidateInfo,
  ElectionArticleInfo,
  PartyDominanceInfo,
  Race,
  RegionSummary
} from "../types"
import { GeoJSONFeatureProperties } from "../types"

export const capitalize = (word: string) =>
  word
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLocaleLowerCase())
    .join(" ")

const stateTitleTemplate = (race: Race, state: string, year: string) =>
  `${state} state ${RACE_ADJECTIVE[race]} election results and data ${year} - Stears Elections`

const countryTitleTemplate = (race: Race, year: string) =>
  `${capitalize(
    RACE_ADJECTIVE[race]
  )} election results and data ${year} - Stears Elections`

const stateDescriptionTemplate = (race: Race, state: string, year: string) =>
  `Get live ${state} ${RACE_ADJECTIVE[race]} election results and data by state for Nigeria ${year}.`

const countryDescriptionTemplate = (
  race: Race,
  breakdown: "senatorial district" | "constituency" | "state",
  year: string
) =>
  `Get live ${RACE_ADJECTIVE[race]} election results and data by ${breakdown} for Nigeria ${year}.`

const PAGE_TITLE: Record<
  Race,
  {
    country: (year: string) => string
    state?: (state: string, year: string) => string
  }
> = {
  president: {
    country: (year: string) =>
      `Presidential election results and data ${year} - Stears Elections`,
    state: (state: string, year: string) =>
      stateTitleTemplate("president", state, year)
  },
  governor: {
    country: (year: string) => countryTitleTemplate("governor", year),
    state: (state: string, year: string) =>
      stateTitleTemplate("governor", state, year)
  },
  senate: {
    country: (year: string) => countryTitleTemplate("senate", year),
    state: (state: string, year: string) =>
      stateTitleTemplate("senate", state, year)
  },
  house: {
    country: (year: string) => countryTitleTemplate("house", year),
    state: (state: string, year: string) =>
      stateTitleTemplate("house", state, year)
  },
  state_house: {
    country: (year: string) => countryTitleTemplate("state_house", year),
    state: (state: string, year: string) =>
      stateTitleTemplate("state_house", state, year)
  }
}

const PAGE_DESCRIPTION: Record<
  Race,
  {
    country: (year: string) => string
    state?: (state: string, year: string) => string
  }
> = {
  president: {
    country: (year: string) =>
      `Get the latest results on ${year} Nigerian Presidential Elections from Stears. ${
        parseInt(year) >= parseInt(UPCOMING_ELECTIONS_YEAR)
          ? "Get live results & data as announced by the Independent National Electoral Commission."
          : ""
      }`,
    state: (state: string, year: string) =>
      stateDescriptionTemplate("governor", state, year)
  },
  governor: {
    country: (year: string) =>
      countryDescriptionTemplate("governor", "state", year),
    state: (state: string, year: string) =>
      stateDescriptionTemplate("governor", state, year)
  },
  senate: {
    country: (year: string) =>
      countryDescriptionTemplate("senate", "senatorial district", year),
    state: (state: string, year: string) =>
      stateDescriptionTemplate("senate", state, year)
  },
  house: {
    country: (year: string) =>
      countryDescriptionTemplate("house", "constituency", year),
    state: (state: string, year: string) =>
      stateDescriptionTemplate("house", state, year)
  },
  state_house: {
    country: (year: string) =>
      countryDescriptionTemplate("state_house", "constituency", year),
    state: (state: string, year: string) =>
      stateDescriptionTemplate("state_house", state, year)
  }
}

export const pageTitle = (
  race: Race,
  state?: string,
  year: string = UPCOMING_ELECTIONS_YEAR
) =>
  state === undefined
    ? PAGE_TITLE[race].country(year)
    : PAGE_TITLE[race].state!(state, year)

export const pageDescription = (
  race: Race,
  state?: string,
  year: string = UPCOMING_ELECTIONS_YEAR
) =>
  state === undefined
    ? PAGE_DESCRIPTION[race].country(year)
    : PAGE_DESCRIPTION[race].state!(state, year)

const PREVIOUS_RACE_YEARS = {
  ED: 2020,
  ON: 2020,
  AN: 2021,
  EK: 2022,
  OS: 2022
}

export const noCandidatesMessage = (
  race: Race,
  stateCode?: string,
  stateName?: string
) => {
  if (
    race === "governor" &&
    stateName &&
    stateCode &&
    Object.keys(PREVIOUS_RACE_YEARS).includes(stateCode)
  ) {
    return `${stateName} State does not have a ${
      RACE_ADJECTIVE[race]
    } election in 2023, because it held its ${
      RACE_ADJECTIVE[race]
    } election in ${(PREVIOUS_RACE_YEARS as any)[stateCode]}.`
  }
  if (
    race === "governor" &&
    stateName &&
    stateCode &&
    ["BY", "KO"].includes(stateCode)
  ) {
    return `${stateName} State ${RACE_ADJECTIVE[race]} election will happen later in 2023. Candidates information will become available after INEC release.`
  }
  return "Oops. It seems something's gone wrong... Please refresh the page or contact us at support@stears.co"
}

export const idFromGeoJSONProperties = ({
  id
}: Pick<GeoJSONFeatureProperties, "id">): string => id

export const titleFromGeoJSONProperties = ({
  name
}: Pick<GeoJSONFeatureProperties, "name">): string =>
  name.toString().replaceAll("/", ",\n")

export const selectMapFeaturesForState = (
  data: FeatureCollection,
  state?: string
): FeatureCollection => ({
  ...data,
  features: data.features.filter(
    ({ properties }) => properties?.state === state
  )
})

export const campaignToCandidateInfo = (
  campaign: Campaign
): CandidateCampaignInfo => {
  const votePercentage =
    campaign.votes && campaign.total_votes
      ? getPercentage(campaign.votes, campaign.total_votes)
      : null

  return {
    slug: campaign.candidate_slug,
    name: campaign.candidate_name,
    party: campaign.party_code,
    region: campaign.region_code as keyof typeof REGION,
    state: campaign.state_code,
    constituency: campaign.constituency_code,
    /**
     * alias to constituency
     */
    constituency_code: campaign.constituency_code,
    stateName: campaign.state_name,
    year: campaign.year,
    race: campaign.race as keyof typeof RACE_TO_NAME,
    href: URLFactory.candidate(campaign.candidate_slug),
    partyColor: campaign.party_color,
    partyCode: campaign.party_code,
    partyLogoURL: campaign.party_logo_url,
    image: campaign.candidate_photo_url
      ? campaign.candidate_photo_url
      : campaign.party_logo_url,
    votes: campaign.votes,
    totalVotes: campaign.total_votes,
    votePercentageFormatted: votePercentage ? `${votePercentage}%` : null,
    votePercentage,
    ...skipEmpty({
      won: campaign.won,
      winner: campaign.won,
      lga: campaign.lga_name,
      lgaCode: campaign.lga_code,
      age: campaign.candidate_age,
      gender: campaign.candidate_gender
        ? ((campaign.candidate_gender || "").toLowerCase() as "m" | "f")
        : undefined,
      runningMate: campaign.running_mate,
      incumbent: campaign.candidate_is_incumbent,
      photoURL: campaign.candidate_photo_url
    })
  }
}

export const articleToElectionArticleInfo = ({
  title,
  slug,
  image,
  author,
  date,
  main_topic
}: Article): ElectionArticleInfo => ({
  title,
  slug,
  href: URLFactory.commonPages.article(slug),
  img: (image as any).source_url || image,
  author: (author as any).name || author,
  imgAlt: (image as any).alt_text || title,
  topicColor: "gray",
  ...skipEmpty({
    date: date,
    imgSrcSet: (image as any).src_set,
    topicName: main_topic?.name,
    topicHref: main_topic
      ? URLFactory.commonPages.topic(main_topic?.slug)
      : URLFactory.commonPages.article(slug)
  })
})

const website = (link?: string) =>
  !link || link.startsWith("http") ? link : "https://" + link

export const candidateToCandidateInfo = (
  candidate: Candidate
): CandidateInfo => ({
  slug: candidate.slug,
  name: candidate.full_name,
  party: candidate.party_code,
  ...skipEmpty({
    age: candidate.age,
    state: candidate.state_name,
    gender: candidate.gender
      ? (candidate.gender.toLowerCase() as "m" | "f")
      : undefined,
    photoURL: candidate.photo_url,
    partyLogoURL: candidate.party_logo_url,
    bio: skipEmpty({
      objective: candidate.bio?.mandate,
      website: website(candidate.bio?.website),
      education: candidate.bio?.education,
      background: candidate.bio?.background,
      notableMentions: candidate.bio?.mentions,
      offices: candidate.bio?.offices,
      workExperience: candidate.bio?.work,
      readMore: candidate.bio?.read_more?.map?.(({ text, url }) => ({
        text,
        url
      }))
    })
  })
})

export const partyDominanceInfoToPartyDominanceInfo = (
  party: PartyDominance
): PartyDominanceInfo => ({
  race: party.race,
  year: party.year,
  party: party.party,
  won: party.won,
  stateCode: party.state,
  stateName: party.state_name,
  ...skipEmpty({
    lga: party.lga_name,
    lgaCode: party.lga_code,
    voteCount: party.vote_count,
    votes: party.vote_count,
    votePercentage: party.vote_percentage,
    partyColor:
      party.party === "Others"
        ? ELECTION_PARTY_COLOR_OTHERS
        : party.party_color ?? undefined,
    partyLogoURL: party.party_logo_url,
    image: party.candidate_photo_url
      ? party.candidate_photo_url
      : party.party_logo_url,
    candidatePhotoURL: party.candidate_photo_url,
    candidateSlug: party.candidate_slug,
    candidateName: party.candidate_name,
    name: party.candidate_name,
    constituencyName: party.constituency_name,
    constituencyCode: party.constituency_code
  })
})

export const createDropdownItems = (
  race: Race,
  year = DEFAULT_ELECTION_YEAR,
  regions: Array<RegionSummary>,
  timeline?: ElectionTime[]
) => {
  const time = timeline?.find((time) => time.year === year)
  return regions.map(({ code, name }) => ({
    name: DROPDOWN_NAME_OVERRIDE[code] || name,
    key: code,
    href: URLFactory.election(race, year, code),
    hasElectionActivity: !time || raceHasElectionInTime(time, race, code)
  }))
}

export const createBreadcrumbItems = (
  race: Race,
  year = UPCOMING_ELECTIONS_YEAR,
  raceURL?: string,
  state?: RegionSummary
): Array<Breadcrumb> => {
  return [
    { href: URLFactory.governor(year), text: "Home" },
    skipEmpty({
      href: raceURL,
      text: RACE_TO_NAME[race]
    }),
    state && {
      href: URLFactory.election(race, year, state.code),
      text: state.name
    }
  ].filter(Boolean) as Array<Breadcrumb>
}

export const isFollowing = (
  constituencies: Array<Constituency>,
  preferences: Array<ElectionPreference>
): boolean => {
  const preferencesByDistrict = byKey<ElectionPreference>(
    preferences,
    "election_id"
  )
  return constituencies.every(({ code }) => preferencesByDistrict[code]?.active)
}

const RENAME_CONSTITUENCIES_FOR_RACE: Race[] = ["house", "state_house"]
const name = (c: Constituency) =>
  RENAME_CONSTITUENCIES_FOR_RACE.includes(c.race as Race)
    ? c.state_name + " - " + c.name
    : c.name

export const selectMyElectionRaces = (constituencies: Constituency[]) => {
  const districts: Array<Constituency & { id: string }> = constituencies.map(
    (c) => ({
      ...c,
      id: c.code,
      name: name(c)
    })
  )
  const grouped = groupBy(districts, (district) => district.race)
  const races = Object.entries(grouped).map(([race, districts]) => ({
    name: RACE_TO_NAME[race] || race,
    slug: race as Race,
    districts
  }))

  return { districts, races }
}
type PersonLd = {
  "@id": string
  name: string
  age?: string
  gender?: "Male" | "Female"
}
export const personLd = (fields: PersonLd) => ({
  "@context": "https://schema.org",
  "@type": "EventSeries",
  ...fields
})
type EventSeriesLd = {
  "@id": string
  name: string
  subEvent?: string
}
export const eventSeriesLd = (fields: EventSeriesLd) => ({
  "@context": "https://schema.org",
  "@type": "EventSeries",
  ...fields
})

type EventLd = {
  "@id": string
  name: string
  url: string
  location: string
  startDate: string // e.g. "2016-08-05"
  endDate: string
  superEvent?: string
  subEvent?: string
}
export const eventLd = (fields: EventLd) => ({
  "@context": "https://schema.org",
  "@type": "Event",
  ...fields
})

export const extractPartyDominanceTableHeaders = (
  partyDominance: PartyDominance[]
) =>
  partyDominance?.reduce(
    (
      headers: TableHeaderV2<PartyDominanceTableRow>[],
      row: PartyDominance
    ): TableHeaderV2<PartyDominanceTableRow>[] => {
      if (headers.find((header) => header.id === row.party)) {
        return headers
      }

      return [
        ...headers,
        {
          name: row.party,
          id: row.party,
          backgroundColor: row.party_color ?? "#4B5563",
          meta: { imageUrl: row.party_logo_url },
          key: `${row.party}_vote_percentage`
        }
      ]
    },
    [] as TableHeaderV2<PartyDominanceTableRow>[]
  )

export const extractPartyDominanceTableData = (
  partyDominance: PartyDominance[],
  by: keyof PartyDominance,
  options?: {
    keyField?: keyof PartyDominance
    generateHref?: boolean
    useDominance?: boolean
  }
) => {
  // extraction options
  const xOptions = { generateHref: true, ...options }
  const useDominance = xOptions?.useDominance ?? false

  return Object.values(
    (partyDominance || []).reduce(
      (
        rows: Record<string, PartyDominanceTableRow>,
        row: PartyDominance
      ): Record<string, PartyDominanceTableRow> => {
        const labelValue = row[by] as string

        const internalRow = rows[labelValue]
        const party_vote_key = `${row.party}_vote_percentage`

        const votePercentagePropKey = useDominance
          ? "dominance"
          : "vote_percentage"
        const NotAvailableValue = useDominance ? "0%" : "N/A"
        const votePercentageProp = row[votePercentagePropKey]
        const votePercentage = votePercentageProp
          ? `${votePercentageProp}%`
          : row.won
          ? ""
          : NotAvailableValue

        if (internalRow) {
          internalRow[party_vote_key] = votePercentage
          if (!internalRow.winner) {
            internalRow.winner = row.won ? party_vote_key : ""
          }

          return rows
        }

        return {
          ...rows,
          [labelValue]: {
            name: capitalize(labelValue ?? ""),
            key: row[xOptions.keyField ? xOptions.keyField : by] as string,
            [party_vote_key]: votePercentage,
            winner: row.won ? party_vote_key : "",
            href: xOptions.generateHref
              ? URLFactory.election(
                  row.race as Race,
                  row.year as string,
                  row.state
                )
              : null
          }
        }
      },
      {} as Record<string, PartyDominanceTableRow>
    )
  )
}

export const extractPartyDominancePartyData = (
  partyDominanceData: PartyDominance[],
  useDominance = false
): Array<PartyDominancePartyData> =>
  Object.values(
    partyDominanceData.reduce(
      (
        rows: Record<string, PartyDominancePartyData>,
        row: PartyDominance
      ): Record<string, PartyDominancePartyData> => {
        const votes = row.vote_count ?? 0
        const totalVotes = row.total_votes ?? 0
        const won = useDominance ? (row.dominance ?? 0) > 0 : row.won

        const internalRow = rows[row.party]
        if (internalRow) {
          rows[row.party] = {
            ...internalRow,
            seatsWon: won ? internalRow.seatsWon + 1 : internalRow.seatsWon,
            votes: internalRow.votes + votes,
            totalVotes: internalRow.totalVotes + totalVotes
          }

          return rows
        }

        return {
          ...rows,
          [row.party]: {
            name: row.party,
            code: row.party,
            color: row.party_color,
            logoURL: row.party_logo_url,
            race: row.race,
            seatsWon: won ? 1 : 0,
            votes,
            totalVotes,
            votePercentage: null
          }
        }
      },
      {} as Record<string, PartyDominancePartyData>
    )
  )

export const sumCandidateCampaignVote = (
  candidate: CandidateCampaignInfo[]
): Array<CandidateCampaignInfo> =>
  Object.values(
    candidate.reduce(
      (
        rows: Record<string, CandidateCampaignInfo>,
        row: CandidateCampaignInfo
      ): Record<string, CandidateCampaignInfo> => {
        const votes = row.votes ?? 0
        const totalVotes = row.totalVotes ?? 0

        const internalRow = rows[row.slug]
        if (internalRow) {
          rows[row.slug] = {
            ...internalRow,
            votes: (internalRow.votes ?? 0) + votes,
            totalVotes: (internalRow.totalVotes ?? 0) + totalVotes
          }

          return rows
        }

        return {
          ...rows,
          [row.slug]: {
            ...row,
            votes,
            totalVotes,
            stateName: undefined,
            votePercentage: null,
            votePercentageFormatted: null
          }
        }
      },
      {} as Record<string, CandidateCampaignInfo>
    )
  )

export const selectLegendFromParties = (parties: Array<PartyDominanceInfo>) =>
  Object.entries(
    parties.reduce(
      (acc, { party, partyColor }) =>
        party && partyColor ? { ...acc, [party]: partyColor } : acc,
      {}
    )
  ).map(([name, color]) => ({ name, color } as { name: string; color: string }))

export const parseRawTimelineData = (
  timeline: Array<RawElectionTime>
): Array<ElectionTime> =>
  Object.values(
    timeline.reduce(
      (
        timeline: Record<ElectionTime["year"], ElectionTime>,
        time: RawElectionTime
      ): Record<ElectionTime["year"], ElectionTime> => {
        const electionYear = String(time.year) // assert year is always string

        // ignore years before the start year
        if (parseInt(time.year) < HISTORICAL_RESULTS_START_YEAR) return timeline

        const generalElectionRaces = ["president", "senate", "house"]
        const isGeneralElectionYear = generalElectionRaces.includes(time.race)

        const internalRow = timeline[electionYear]
        if (internalRow) {
          if (internalRow.races[time.race as Race]) {
            internalRow.races[time.race as Race].push(time.state)
          } else {
            internalRow.races = {
              ...internalRow.races,
              [time.race as Race]: [time.state]
            }
          }

          if (!internalRow.isGeneralElectionYear)
            internalRow.isGeneralElectionYear = isGeneralElectionYear

          return timeline
        }

        return {
          ...timeline,
          [electionYear]: {
            year: electionYear,
            isGeneralElectionYear,
            races: { [time.race as Race]: [time.state] }
          }
        }
      },
      {} as Record<ElectionTime["year"], ElectionTime>
    )
  )

export const findAndRemoveStateHouseTimeDataBefore2023 = (
  time: ElectionTime
) => {
  // find and remove state house data "< 2023 a.k.a UPCOMING_ELECTIONS_YEAR" data
  const isBefore2023 = parseInt(time.year) < parseInt(UPCOMING_ELECTIONS_YEAR)
  if (isBefore2023 && time.races?.state_house) delete time.races.state_house

  return time
}
