import { API_BASE_URL } from "@/src/common/config"
import { qs } from "@/src/common/utils"
import { ExternalHTTP } from "@/src/elections/data-layer"
import useSWRImmutable from "swr/immutable"

import { PaginatedResponse } from "../../data-layer/types"
import { ELECTION_ARTICLE_FEED_API_URL } from "../config"
import { articleToElectionArticleInfo } from "../selectors"
import { Article, ElectionArticleInfo, Race } from "../types"

export default function useElectionArticles(
  externalHTTP: ExternalHTTP,
  race?: Race,
  state?: string,
  initialArticles: Array<ElectionArticleInfo> = []
) {
  const query = qs({
    personalized: "false",
    sort: "desc",
    limit: "6",
    race,
    state
  })

  const { data, error, isValidating } = useSWRImmutable<
    PaginatedResponse<Article>
  >(
    [API_BASE_URL + ELECTION_ARTICLE_FEED_API_URL + query],
    externalHTTP.get.bind(externalHTTP)
  )

  const articles = (data?.items || []).map(articleToElectionArticleInfo)

  return {
    articles: articles.length != 0 ? articles : initialArticles,
    loading: isValidating,
    error
  }
}
